<template>
  <div id="app">
    <div id="nav">
        <top-menu></top-menu>
    </div>
    <div style='height:50px'></div>
    <router-view/>
    <contact-us></contact-us>
    <my-footer></my-footer>

  </div>
</template>


<script>
import menu from './components/TopMenu.vue';
import footer from './components/MyFooter.vue';
import contactUs from './components/ContactUs.vue';


 
export default{
    components: {
        TopMenu: menu,
       MyFooter: footer,
       ContactUs:contactUs
 
       
      }
} 
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#nav{
  width: 100% ;
  position: fixed;
  top: 0;
  z-index: 9;
}
</style>
