<template>
    <div class='bannerContainer'  :style="{backgroundImage: 'url(' + img + ')'}">
     <div class='bannerBlock'>
        <div v-show='name'>
            <img :src="icon" alt="" class='icon'>
            <span class='nameTitle'>{{name}}</span>
        </div> 
        <div class='title'>{{title}} </div>
        <div class='subTitle'>{{text}}</div>
        <div class='btn' @click='showModel'>立即体验</div>
    </div> 
    </div>
    
</template>

<script>
import eventVue from '../eventBus.js'
export default {
 name: 'ToopBanner',
 components:{
   
 },
  data() {
    return {
     
    };
  },
  props: {
   title: String,
   text:String,
   img:String,
   icon:String,
    name:String
  },
    methods:{
      showModel() {
      eventVue.$emit('changeModel',true)
    },
  }
};
</script>

<style>
.bannerContainer{
    width:100%;
    height:500px;
    background-size: cover;
    position: relative;
  }
.bannerBlock{
    text-align: center;
    width:100% ;
    position:absolute;
    top:80px;

  }
  .bannerBlock .title{
    color: #fff;
    font-size: 42px;
    line-height: 70px;
    margin-bottom: 4px;
    letter-spacing: 3px;
    font-weight: 600;
  }
  .bannerBlock .subTitle{
    opacity: .9;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    margin: 8px 0 60px;
  }
   .btn{
   width:140px;
    cursor: pointer;
    padding: 12px 40px;
    border-radius: 28px;
    border: none;
    font-size: 14px;
    color: #fff;
    z-index: 2;
    outline-style: none;
    background-color: #2f6aff;
    margin-left:calc(50% - 70px)
  }
  .nameTitle{
    font-size: 40px;
    color: rgb(255, 255, 255);
  }
  .icon{
    width: 40px;
    display: inline-block;
    margin-right: 15px;
    vertical-align: sub;
  }
</style>