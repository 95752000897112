import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

router.afterEach(() => {
  window.scrollTo(0,0);
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  document.title='妖星官网'
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})