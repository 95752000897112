<template>
  <div>
    <a-modal class='modelContanier'
      title="预约销售顾问"
      footer=''
      :visible="visible"
      :confirm-loading="confirmLoading"
       @cancel="handleCancel"
    >
      <div class='form-model'>
          <a-input class='input' v-model='name' type="text" placeholder="称呼" style='margin-right:2%'></a-input>
          <a-input  class='input' v-model='phone' type="text"  placeholder="手机号（必填）"></a-input>
          <a-input  class='input' v-model='qq' type="text"  placeholder="微信/QQ" style='margin-right:2%'></a-input>
          <div class='input' style='text-align:left'>
          <span  style='margin-right:20px;color:#ccc'>已有网站</span> 
            <template>
              <a-radio-group name="radioGroup">
                <a-radio :value="1">
                  是
                </a-radio>
                <a-radio :value="2">
                  否
                </a-radio>
              </a-radio-group>  
            </template>
          </div>
      </div>
      <div class='btnModel' style='width:280px' @click='submit'>预约营销顾问 </div>
      <div class='modelFooter'>
        <div class='modelHelp'>
          <div class='modelTitle'>我们随时为您提供帮助</div>
          <div class='modelPhone'>400-666-3288  </div>
        </div>
        <div class='modelRight'>专业致力于广告投放业务，始终坚持着“将客户的广告信息推向全球受众，将客户的产品服务卖向全球市场”的服务宗旨。</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import eventVue from '../eventBus.js'
export default {
 name: 'MyModel',
 components:{
   
 },
  data() {
    return {
    confirmLoading: false,
    name:'',
    phone:'',
    qq:'',
    visible:false
    };
  },
  props: {
   title: String,
   tabList:Array,
   
  },
  methods:{
      handleCancel() {
      this.visible = false;
    },
    submit(){
      if(!this.phone){
        this.$message.error('请填写手机号');
      }
      else{
        this.$message.success('感谢您的预约');
        this.visible = false;
      }
    }
  },
  mounted(){
    eventVue.$on('changeModel',data=>{
      this.visible = data;
    })
  }

}
</script>

<style>
.ant-modal-content{
  width: 600px !important;
}
.form-model .input{
  width: 260px;
  border-radius: 50px!important;
    height: 48px!important;
    line-height: 48px!important;
    margin-bottom: 40px!important;
    padding: 0 20px!important;
    border: 1px solid #dbe5ed!important;
    display: inline-block;
}
.btnModel{
    border: none;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    margin: 20px auto;
    width: 610px;
    background-color: #296bef;
    box-shadow: 0 1px 0 rgba(44,114,255,.08), 0 6px 12px rgba(44,114,255,.18);
    text-align: center;
    color: #fff;
}
.modelFooter{
  padding:0 20px;  
}
.modelTitle{
  font-size: 16px;
    font-weight: 400;
    color: #222;
    line-height: 24px;
}
.modelPhone{
  font-size: 32px;
    color: #2f6aff;
    line-height: 44px;
    letter-spacing: -1px;
}
.modelRight{
    display: inline-block;
    width: 60%;
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 24px;
    text-align: left;
    padding: 0 30px;
}
.modelHelp{
  margin-top: 30px;
  display: inline-block;
  width:40%;
  border-right:1px solid #e3e3e3;

}
</style>